
import {
    computed,
    defineComponent, reactive, ref
} from 'vue';
import List from '@/components/common/list/src/list.vue';
import { residentDeviceApi } from '@/api';
import { overlayFormData } from '@/util/system';
import { ListActionItem } from '@/components/common/list';
import removeFunc from '@/methods/remove-func';
import CameraDevicesOperateDialog
    from '@/components/view/installer/community/build-content-model/components/camera-devices-operate-dialog.vue';
import addButton from '@/components/common/add-button/index.vue';
import { updateSortField, getInitSortField, getSortImg } from '@/methods/sort-table';

export default defineComponent({
    components: {
        List,
        CameraDevicesOperateDialog,
        addButton
    },
    props: {
        build: {
            type: String,
            default: 'community'
        },
        // 第三方设备三级菜单标识
        deviceType: {
            type: String,
            default: 'camera'
        }
    },
    setup(props) {
        // 获取build和room框数据
        const updateData = ref(0);
        // 搜索条件
        const formData = reactive({
            searchKey: 'Location',
            searchValue: '',
            ID: props.build,
            row: 10,
            page: 1,
            SortField: '',
            Sort: ''
        });
        overlayFormData(formData);

        const grade = computed(() => {
            if (props.build === 'community' || props.build === 'public') {
                return 1;
            }
            return 2;
        });

        const searchKeyList = [{
            label: WordList.TabelPersonDeviceInHtmlLocation,
            name: 'Location'
        }, {
            label: WordList.ProperAllTextResident,
            name: 'Owner'
        }];

        // 表头   迁移name 改成prop,增加 {
        //     name: 'op',
        //         label: WordList.CaptureListTanleAction
        // }
        const column = props.build === 'community' ? [
            {
                name: 'Location',
                label: WordList.TabelPersonDeviceInHtmlLocation,
                headerType: 'customize'
            }, {
                name: 'Name',
                label: WordList.ProperAllTextResident
            }, {
                name: 'RoomName',
                label: WordList.RDeviceSearchLabelRoomName
            }, {
                name: 'UnitName',
                label: WordList.DeviceDetailDetailUnitName
            }, {
                name: 'LinkDevice',
                label: WordList.LinkDevice
            }, {
                name: 'CreateTime',
                label: WordList.TabelMessageBoxCreateTime,
                headerType: 'customize'
            }
        ] : [
            {
                name: 'Location',
                label: WordList.TabelPersonDeviceInHtmlLocation,
                headerType: 'customize'
            }, {
                name: 'LinkDevice',
                label: WordList.LinkDevice
            }, {
                name: 'CreateTime',
                label: WordList.TabelMessageBoxCreateTime,
                headerType: 'customize'
            }
        ];

        // 表格数据获取
        const tableData = reactive<{row: object[];detail: object[];total: number}>({
            row: [],
            detail: [],
            total: 0
        });

        // 获取第三方设备数据
        const getThirdPartyDeviceApi = () => {
            residentDeviceApi.thirdPartCameraGetListForCommunity(
                formData,
                (res: {row: object[];detail: object[];total: number}) => {
                    tableData.row = res.row;
                    tableData.detail = res.detail;
                    tableData.total = res.total;
                    updateData.value += 1;
                }
            );
        };
        getThirdPartyDeviceApi();

        // 删除
        const deleteThirdPartyDeviceApi = (data: { UUID: string }) => {
            removeFunc(WordList.TabelDeleteText, () => {
                residentDeviceApi.thirdPartCameraDelete({ UUID: data.UUID }, getThirdPartyDeviceApi);
            });
        };

        // 操作
        const isShowOperateDialog = ref(false);
        const operateType = ref<'add'|'edit'>('add');
        const editData = ref({});

        const add = () => {
            operateType.value = 'add';
            isShowOperateDialog.value = true;
        };

        const edit = (item: object) => {
            operateType.value = 'edit';
            editData.value = item;
            isShowOperateDialog.value = true;
        };

        const cameraClose = () => {
            isShowOperateDialog.value = false;
        };

        // action操作 迁移备注：这部分不用迁移 主要是为了适配删除触发点击
        const action: ListActionItem[] = [
            {
                type: 'edit2',
                event(data) {
                    edit(data);
                },
                class: 'el-icon-my-modify modify-icon',
                title: WordList.TabelConfigInHtmlModify
            },
            {
                type: 'delete2',
                event(data: { UUID: string }) {
                    deleteThirdPartyDeviceApi(data);
                },
                class: 'el-icon-delete delete-icon',
                title: WordList.TabelConfigInHtmlDelete
            }
        ];

        // 获取排序表格名称
        const sortTableName = computed(() => {
            if (props.build === 'community') {
                return 'communityThirdPartyDevice';
            }
            if (props.build === 'public') {
                return 'pubicThirdPartyDevice';
            }
            return 'buildThirdPartyDevice';
        });

        // 获取初始排序
        const initSortRes = getInitSortField(sortTableName.value);
        formData.SortField = initSortRes.sortField;
        formData.Sort = initSortRes.sort;
        // 获取排序方式
        function getSortRes(table: string, sortField: string) {
            const { sort } = updateSortField(table, sortField);
            formData.SortField = sortField;
            formData.Sort = sort;
            getThirdPartyDeviceApi();
            updateData.value += 1;
        }

        return {
            formData,
            column,
            getThirdPartyDeviceApi,
            tableData,
            updateData,
            action,
            searchKeyList,
            isShowOperateDialog,
            edit,
            editData,
            cameraClose,
            add,
            operateType,
            grade,
            sortTableName,
            getSortRes,
            getSortImg
        };
    }
});
